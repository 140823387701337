@media only screen and (min-width: 769px) {
  #content.contact {
    display: flex;
    width: max-content;
  }
  #content.contact address {
    height: fit-content;
    background-color: var(--odd-row);
  }
}
