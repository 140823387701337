#home {
  width: 100%;
}

#left_content {
  grid-column: 1;
  padding-top: 15px;
  padding-left: 1.5em;
  height: fit-content;
}

#home #content {
  grid-column: 2/4;
  text-align: center;
}

#right_content {
  grid-column: 4;
  width: 20vw;
  height: fit-content;
  padding-top: 15px;
  padding-left: 1.5em;
}

#left_content ul li {
  list-style: disc;
}

@media only screen and (max-width: 768px) {
  #home {
    display: block;
  }
  #logo {
    width: min(100vw, 100vh);
    height: min(100vw, 100vh);
  }

  #left_content * {
    width: fit-content;
  }
  #left_content ul {
    display: flex;
    width: fit-content;
    overflow-x: hidden;
  }
  #left_content ul li {
    list-style: none;
    margin: 0;
    margin-left: 5px;
  }

  #left_content,
  #right_content {
    width: calc(100% - 24px);
    max-width: 100vw;
  }

  #left_content ul li:not(:last-of-type)::after {
    content: ",";
  }

  #left_content2 {
    margin-top: 15px;
    padding-left: 1.5em;
    width: fit-content !important;
    height: fit-content;
  }
}

@media screen and (min-width: 769px) {
  #home {
    display: grid;
    column-gap: 0;
  }
  #logo {
    width: min(90vw, 95vh - 10em);
    height: min(90vw, 95vh - 10em);
  }
}
