.bug td:has(input[type="submit"]) {
  text-align: center;
}

.bug label[for="agreement"] {
  font-size: 0.6em;
}

@media screen and (min-width: 769px) {
  #content.bug {
    width: fit-content;
    margin: auto;
  }
  .bug label[for="agreement"] {
    display: inline-flex;
    font-size: 0.6em;
    max-width: 65vw;
  }
}

@media screen and (max-width: 768px) {
  .bug table {
    display: block;
  }

  .bug tr,
  .bug div {
    display: flex;
  }
  .bug label {
    float: left;
    margin-right: 5px;
  }

  .bug input[type="checkbox"] {
    float: left;
  }
  .bug #notices {
    width: 90vw;
  }

  .bug tr {
    display: flex;
    flex-direction: column;
  }
}
