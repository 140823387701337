#game .error {
  background-color: red;
}

#game .error * {
  color: var(--document_font_color);
  text-decoration: none;
}

#game #content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: unset;
}

#game #first,
#game #previous,
#game #next,
#game #last,
#game #first a,
#game #previous a,
#game #next a,
#game #last a {
  color: var(--document_font_color);
}

#board_analysis {
  display: grid;
}

#game #buttonContainer {
  text-align: center;
  margin-top: 5px;
}

#game #engine_container {
  background-color: var(--document_content_background);
  width: 150px;
  padding-top: 15px;
}

#game #board {
  grid-row: 1;
}

#game #engine_container p {
  text-wrap: wrap;
  max-height: 3em !important;
  overflow-y: auto;
}

#game #info {
  text-align: center;
}

#game #notation,
#game #board {
  background-color: var(--document_content_background);
}

@media only screen and (max-width: 768px) {
  #game #board {
    grid-column: 1/4;
  }

  #game #content3 {
    display: flex;
    justify-content: center;
  }
  #game #buttonContainer {
    display: grid;
    gap: 0;
    grid-template-columns: min-content min-content;
  }
  #game #first {
    grid-row: 1;
    grid-column: 1;
  }
  #game #previous {
    grid-row: 2;
    grid-column: 1;
  }
  #game #next {
    grid-row: 2;
    grid-column: 2;
  }

  #game #last {
    grid-row: 1;
    grid-column: 2;
  }
  #game #engine_container {
    grid-row: 2;
    grid-column: 2;
  }

  .inactive {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  #game #buttonContainer {
    display: flex;
    flex-wrap: nowrap;
  }
  #game #engine_container {
    grid-row: 1;
    grid-column: 3;
  }

  #game #board {
    grid-column: 1/3;
  }
}
