.white,
.white * {
  color: white;
}

.black,
.black * {
  color: black;
}

.whiteContour,
.whiteContour * {
  color: white;
}

.blackContour,
.blackContour * {
  color: black;
}

.gray,
.gray * {
  color: gray;
}

.green,
.green * {
  color: green;
}

#notation,
#notation span,
#notation p {
  color: black;
  background-color: white;
}

.piece:hover {
  cursor: pointer;
}

.piece:active {
  cursor: grabbing;
}

.move:hover {
  cursor: crosshair;
}

span.piece,
span.target {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

span.piece svg,
span.target svg {
  width: 50% !important;
  height: 50% !important;
}

span.piece svg,
span.target svg {
  width: 75% !important;
  height: 75% !important;
}

.light .control:not(.disabled),
.light .control:not(.disabled) * {
  color: black;
  transition: color 0.3s ease;
}

.dark .control:not(.disabled),
.dark .control:not(.disabled) * {
  color: white;
  transition: color 0.3s ease;
}

.control:hover:not(.disabled),
.control:hover:not(.disabled) * {
  color: gray;
  cursor: pointer;
}

.control:active,
.control:active * {
  cursor: grabbing;
}

.control.disabled,
.control.disabled * {
  color: gray;
}

.control:hover.disabled,
.control:hover.disabled * {
  cursor: not-allowed;
}

.promotion {
  cursor: pointer;
}

.promotion * {
  color: black;
}

#info {
  background-color: var(--document_content_background);
  width: fit-content;
  margin: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gainsboro;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: black;
}

#notation:empty {
  width: 100px;
}

.dark ::-webkit-scrollbar-thumb {
  background: darkcyan !important;
  border-radius: 10px;
}

.dark ::-webkit-scrollbar-thumb:hover {
  background: #b30000 !important;
}

.dark #notation,
.dark #notation span,
.dark #notation p {
  color: white;
  background-color: black;
}

.dark .promotion * {
  color: white;
}

*:focus {
  outline: none;
}

@media only screen and (min-width: 769px) {
  #notation p {
    display: table-row;
  }

  #notation span {
    display: table-cell;
  }

  .control.switchNotation {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  #content3 {
    display: flex;
    justify-content: center;
  }
  #first {
    grid-row: 2;
    grid-column: 1;
  }
  #previous {
    grid-row: 1;
    grid-column: 1;
  }
  #next {
    grid-row: 1;
    grid-column: 2;
  }

  #last {
    grid-row: 2;
    grid-column: 2;
  }

  #notation p {
    display: inline;
  }

  .control.switchNotation {
    display: inline;
  }
}
